<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Servidores</template>
        <template #content>
          <div class="flex mb-3">
            <form @submit.prevent="onFilter()">
              <div class="p-inputgroup">
                <InputText
                  v-model="filters.matriculaCpf.value"
                  autofocus
                  type="search"
                  :allowEmpty="true"
                  :useGrouping="false"
                  placeholder="CPF, Matrícula ou E-mail" />
                <Button class="p-button" @click="onFilter()">
                  <span v-if="!loading" class="pi pi-search"></span>
                  <span v-if="loading" class="pi pi-spin pi-spinner"></span>
                  <span class="ml-2">Buscar</span>
                </Button>
              </div>
            </form>
            <Button
              class="ml-2 pr-3 p-button-secondary"
              icon="pi pi-filter-slash"
              label="Limpar"
              @click="clearFilters" />
          </div>
          <DataTable
            v-show="exibeTabela"
            :value="servidores"
            stripedRows
            :paginator="true"
            :rows="10"
            :loading="loading"
            :totalRecords="totalRecords"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} servidores"
            responsiveLayout="scroll">
            <template #empty> Nenhum servidor encontrado. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column
              v-if="$auth.hasRoleGestao()"
              field="id"
              header="ID"></Column>
            <Column field="cpf" header="CPF"></Column>
            <Column field="nome" header="Nome"></Column>
            <Column field="matricula" header="Matrícula"></Column>
            <Column field="entidadeNome" header="Entidade"></Column>
            <Column field="vinculoNome" header="Vínculo">
              <template #body="{ data }">
                <span
                  v-if="
                    data.vinculoNome === 'PENSIONISTA' ||
                    data.vinculoNome === 'APOSENTADO' ||
                    data.vinculoNome === 'EFETIVO'
                  "
                  style="color: green">
                  {{ data.vinculoNome }}
                </span>
                <span v-else style="color: red">
                  {{ data.vinculoNome }}
                </span>
              </template>
            </Column>
            <Column header="Situação">
              <template #body="{ data }">
                <Tag
                  :value="data.validacao.value"
                  :severity="data.validacao.severity"
                  :icon="data.validacao.icon">
                </Tag>
              </template>
            </Column>
            <Column header="Ações">
              <template #body="slotProps">
                <Button
                  v-tooltip.left="'Detalhes'"
                  class="p-button-rounded p-button-success mr-2"
                  @click="detalheServidor(slotProps.data)">
                  <span v-if="!loadingDetail" class="pi pi-eye"></span>
                  <span
                    v-if="loadingDetail"
                    class="pi pi-spin pi-spinner"></span>
                </Button>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>

      <Dialog
        modal
        header="Header"
        :style="{ width: '350px' }"
        :visible.sync="deleteServidorDialog">
        <template #header>
          <h3>Excluir Servidor</h3>
        </template>

        Confirma a exclusão de {{ servidor.nome }} ?

        <template #footer>
          <Button
            label="Não"
            icon="pi pi-times"
            class="p-button-text"
            @click="deleteServidorDialog = false" />
          <Button
            label="Sim"
            icon="pi pi-check"
            autofocus
            @click="deleteServidor" />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'

export default {
  data() {
    return {
      servidores: null,
      servidor: {},
      deleteServidorDialog: false,
      exibeTabela: false,
      loading: false,
      totalRecords: 0,
      parametros: {},
      filters: {
        matriculaCpf: { value: null, matchMode: 'exactly' },
      },
      loadingDetail: false,
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.parametros = {
      first: 0,
      page: 0,
      filters: this.filters,
    }
  },

  methods: {
    detalheServidor(data) {
      this.loadingDetail = true
      this.$router.push({ name: 'servidores-margem', params: { id: data.id } })
      this.loadingDetail = false
    },

    onPage(event) {
      this.parametros = event
      this.carregarDadosFiltrados()
    },

    onFilter() {
      if (this.filters.matriculaCpf.value) {
        this.parametros.filters = this.filters
        this.carregarDadosFiltrados()
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Informe o CPF ou a matrícula.',
          life: 10000,
        })
      }
    },

    carregarDadosFiltrados() {
      this.loading = true
      this.matriculaService
        .getMatriculas(this.parametros)
        .then((res) => {
          this.servidores = res
          this.totalRecords = res.length
          this.loading = false
          this.exibeTabela = true
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    clearFilters() {
      this.filters.matriculaCpf.value = null
      this.servidores = null
      this.exibeTabela = false
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
